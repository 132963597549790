import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { Button, Card } from '../../theme/components';
import CheckIcon from "../../images/checkmark.svg";
import CheckIconWhite from "../../images/checkmark_white.svg";
import dots from "../../images/dots.png";

export const VideoBanner = styled.section`
	width: 100%;
	height: 100vh;
	position: relative;
`;

export const VideoBannerOverlay = styled.div`
	width: 100%;
	height: 100%;
	background-color: #00000096;
	position: absolute;
	z-index: -1;
`

export const Banner = styled.div`
	z-index: 10;
	height: calc(100vh - 152px);
	width: 100%;
`

export const BannerContents = styled.div`
	max-width: 1200px;
	margin: 0 auto;
	height: 100%;
	padding: 112px 0;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	@media (max-width: 1200px) {
		padding: 64px 0;
  }
	@media (max-width: 600px) {
		padding: 24px 0;
  }
`;

export const BannerTextBox = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	flex-direction: column;
	max-width: 50%;
  @media (max-width: 1200px) {
    margin: 0px 12px;
		max-width: 50%;
  }
	@media (max-width: 960px) {
    margin: 0px 12px;
		max-width: 70%;
  }
	@media (max-width: 600px) {
		max-width: 90%;
  }
`

export const BannerBadge = styled.div`
	height: 36px;
	background-color: #303131CC;
	color: white;
	padding: 4px 12px;
	border-radius: 6px;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Pridi', serif;
`

export const BannerTitle = styled.h2`
	color: white !important;
	@media (max-width: 600px) {
		font-size: 28px;
  }
`

export const BannerButton = styled(Button)`
	margin-right: 12px;
	@media (max-width: 600px) {
		margin-right: 0;
		margin-bottom: 12px;
  }
`

export const PageContents = styled.div`
	max-width: 1200px;
	margin: 0 auto;
`

export const HomeBadge = styled.div`
	height: 32px;
	background-color: #FDE8EC;
	color: #EE2D4F;
	padding: 8px 18px;
	border-radius: 6px;
	font-size: 13px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 12px;
`

export const CenteredSection = styled.section`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 48px 24px;
`;

export const CenteredSectionSmall = styled(CenteredSection)`
	max-width: 960px;
	margin: 0 auto;
`;

export const CenteredHeader = styled.h2`
	text-align: center;
`;

export const CenteredHeaderWhite = styled.h2`
	text-align: center;
	color: white !important;
`;

export const CenteredText = styled.p`
	text-align: center;
`;

export const HomeCard = styled(Card)`
	min-height: 210px;
	padding: 36px 24px;
	max-width: 300px;
	justify-content: center;
	margin-bottom: 24px;
	@media (min-width: 768px) and (max-width: 960px) {
		min-height: 232px;
  }
	@media (min-width: 576px) and (max-width: 768px) {
		min-height: 305px;
  }
	@media (max-width: 575px) {
		min-height: 210px;
  }
`;

export const HomeCardCol = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
`;

export const SmallCardIcon = styled.img`
	height: 36px;
	margin-bottom: 24px;
`

export const StoreCol = styled(Col)`
	margin-bottom: 16px;
`

export const StoreIcon = styled.img`
	cursor: pointer;
	max-height: 60px;
`;

export const Bold = styled.span`
	font-weight: bold;
`;

export const Checklist = styled.ul`
	list-style: none;
	padding-left: 0.6em;
`

export const CheckListItem = styled.li`
	margin-bottom: 12px;
	position: relative;
	list-style: none;
	padding-left: 1.4em;

	&:before {
    content: '';
    display: inline-block;
    height: 1em;
    width: 1em;
    background-image: url(${CheckIcon});
    background-size: contain;
    background-repeat: no-repeat;
		position: absolute;
		left: -0.5em;
		top: 0.25em;
  }
`;

export const CheckListItemWhite = styled(CheckListItem)`
	color: white !important;
	&:before {
    background-image: url(${CheckIconWhite});
  }
`;

export const ImageContainer = styled.div`
	width: 100%;
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	@media (max-width: 600px) {
		max-height: 400px;
		margin-bottom: 24px;
  }
`;

export const HomeImage = styled.img`
	max-width: 100%;
	max-height: 400px;
`;

export const GradientCard = styled(Card)`
	min-height: 265px;
	padding: 36px 18px;
	justify-content: flex-start;
	margin-bottom: 24px;
	background: rgb(255,42,76);
	background: linear-gradient(180deg, rgba(238,44,80,1) 0%, rgba(228,32,96,1) 100%);
	box-shadow: 8px 8px 16px rgba(228,32,96, 0.11);
	width: 100%;
	border: 0;
	@media (min-width: 960px) and (max-width: 1200px) {
		min-height: 260px;
  }
	@media (min-width: 768px) and (max-width: 960px) {
		min-height: 302px;
  }
	@media (max-width: 575px) {
		min-height: 236px;
		max-width: 330px;
  }
`;

export const GradientCardText = styled.p`
	color: white;
	margin-bottom: 0;
`
export const GradientCardTitle = styled(GradientCardText)`
	color: white;
	font-weight: bold;
	font-size: 16px;
	margin-bottom: 8px;
`

export const GradientCardIconContainer = styled.div`
	width: 64px;
	height: 64px;
	height: 100%;
	border-radius: 50%;
  box-shadow: 4px 4px 8px rgba(47,1,9, 0.17);
`

export const GradientCardIcon = styled.img`
	width: 64px;
	height: 64px;
`

export const GradientCardDivider = styled.div`
	background-color: white;
	height: 1px;
	width: 100%;
	margin-right: 24px;
	margin-left: 16px;
`

export const GradientCardHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	margin-bottom: 24px;
`

export const GradientCardsRow = styled(Row)`
	max-width: 1120px;
	background-color: transparent;
`

export const CenteredSectionWithDots = styled(CenteredSection)`
	background-image: url(${dots});
	background-repeat: no-repeat;
	background-size: 18%;
	background-position: 80% 35%;
`;

export const GradientCardCol = styled(Col)`
	background-color: transparent;
`;

export const DottedList = styled.ul`
	list-style: none;
	padding-left: 0;
	padding-left: 0.6em;
`

export const DottedListItem = styled.li`
	margin-bottom: 8px;
	position: relative;
	list-style: none;
	padding-left: 1rem;

	&:before {
    content: '';
    display: inline-block;
    height: 0.5rem;
    width: 0.5rem;
		border-radius: 100%;
		background-color: #FF2A4C;
		position: absolute;
		left: -0.1rem;
		top: 0.35rem;
  }
`;

export const DottedListItemWhite = styled(DottedListItem)`
	color: white;
	&:before {
		background-color: #fff;
  }
`;

export const FullWidthSection = styled.section`
	padding: 36px 24px 12px;
	width: 100%;
	min-height: 500px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	background: rgb(255,42,76);
	background: linear-gradient(180deg, rgba(238,44,80,1) 0%, rgba(228,32,96,1) 100%);
  transition: all 0.5s;
	@media (max-width: 575px) {
		padding: 36px 0 12px;
  }
`;

export const FullWidthSectionPadded = styled(FullWidthSection)`
	padding: 36px 24px;
	min-height: unset;
	@media (max-width: 575px) {
		padding: 36px 12px;
  }
`;

export const NumberedList = styled.ol`
	padding-left: 0;
	padding-left: 0.8em;
`

export const NumberedListItem = styled.li<{ number: string }>`
	margin-bottom: 12px;
	position: relative;
	list-style: none;
	padding-left: 1.2rem;

	&:before {
    content: ${props => `'${props.number}'`};
    display: inline-block;
    height: 1.2rem;
    width: 1.2rem;
		line-height: 1.2rem;
		font-size: 0.8rem;
		border-radius: 100%;
		background-color: #FF2A4C;
		position: absolute;
		left: -0.5rem;
		top: 0rem;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
  }
`;

export const StepRow = styled(Row)`
	margin-bottom: 86px;
`

export const HomeDivider = styled.div`
	margin: 24px auto;
	height: 1px;
	background-color: #e8e8e8;
	max-width: 960px;
	width: 100%;
`