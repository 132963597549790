import React, { useState, useEffect } from 'react';
import title from '../../images/title.svg';
import { MenuWrapper, StyledLinkMobile, StyledLinkMobileCol, MenuContents, HeaderColLeft, HeaderColRight, HeaderColCenter, HeaderContents, StyledHeader, StyledLink, LinksRow, ButtonWrapper, HeaderMenu } from './styled';
import useScroll from "../../hoc/useScroll";
import { Row, Col } from 'antd';
import { LinkButton } from '../../theme/components';
import { Link } from 'gatsby';
import LoginIcon from "../../icons/log_in.svg"

const Header = () => {
	let mql;
	const [path, setPath] = useState("/")
	const [open, setOpen] = useState(false);
	const { y } = useScroll({
		onScroll: undefined,
		onScrollDown: undefined,
		onScrollUp: undefined
	})
	const isVideoMode = path === "/" && !open && y < 120;
	useEffect(() => {
		if (typeof window !== undefined) {
			setPath(window.location.pathname);
			mql = window.matchMedia(`(min-width: 768px)`);
			mql.addListener(mediaQueryChanged);
			mediaQueryChanged();
		}
		return () => {
			if (typeof window !== undefined) {
				mql.removeListener(mediaQueryChanged);
			}
		};
	}, []);
	const mediaQueryChanged = () => {
		if (mql.matches) {
			setOpen(false);
		}
	};
	const toggleMenu = () => {
		setOpen(!open);
	}
	return (
		<StyledHeader>
			<HeaderContents open={open}>
				<Row style={{ height: "100%" }} type="flex" align="middle" justify="space-between" gutter={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24 }}>
					<HeaderColLeft xl={5} lg={5} md={5} sm={10} xs={10}>
						<Link to="/">
							<img src={title} alt='Logo-DeReader' style={{ marginBottom: 0, height: 22 }} />
						</Link>
					</HeaderColLeft>
					<HeaderColCenter xl={13} lg={13} md={13} sm={1} xs={1}>
						<LinksRow>
							<Row type="flex" align="middle" justify="space-between">
								<Col>
									<StyledLink to="/about" title="About">
										About
								</StyledLink>
								</Col>
								<Col>
									<StyledLink to="/how-it-works" title="How it works">
										How it works
								</StyledLink>
								</Col>
								<Col>
									<StyledLink to="/pricing" title="Pricing">
										Pricing
								</StyledLink>
								</Col>
								<Col>
									<StyledLink to="/connect" title="Connect">
										Connect
								</StyledLink>
								</Col>
							</Row>
						</LinksRow>
					</HeaderColCenter>
					<HeaderColRight xl={6} lg={6} md={6} sm={11} xs={11}>
						<ButtonWrapper>
							<LinkButton color="primary" size="default" href="https://app.dereader.ca/login" rel="noreferrer noopener" target="_blank">
								<LoginIcon />Manage your assets
							</LinkButton>
						</ButtonWrapper>
						<MenuWrapper>
							<button
								onClick={toggleMenu}
								style={{ outline: 0 }}
								className={`hamburger hamburger--spin ${open ? 'is-active' : ''}`}
								type="button"
							>
								<span className="hamburger-box">
									<span className="hamburger-inner" />
								</span>
							</button>
						</MenuWrapper>
					</HeaderColRight>
				</Row>
			</HeaderContents>
			<HeaderMenu open={open}>
				<MenuContents open={open}>
					<Row type="flex" align="top" justify="start">
						<StyledLinkMobileCol>
							<StyledLinkMobile to="/about">
								About
								</StyledLinkMobile>
						</StyledLinkMobileCol>
						<StyledLinkMobileCol>
							<StyledLinkMobile to="/how-it-works">
								How it works
								</StyledLinkMobile>
						</StyledLinkMobileCol>
						<StyledLinkMobileCol>
							<StyledLinkMobile to="/pricing">
								Pricing
								</StyledLinkMobile>
						</StyledLinkMobileCol>
						<StyledLinkMobileCol>
							<StyledLinkMobile to="/connect">
								Connect
								</StyledLinkMobile>
						</StyledLinkMobileCol>
					</Row>
					<LinkButton color="primary" size="default" href="https://app.dereader.ca/login" rel="noreferrer noopener" target="_blank">
						<LoginIcon /> Manage your assets
					</LinkButton>
				</MenuContents>
			</HeaderMenu>
		</StyledHeader >
	);
};

export default Header;