import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Row, Col } from 'antd'

export const StyledHeader = styled.header`
  height: 128px;
  margin: 0 auto 24px;
  padding-top: 48px;
  max-width: 1200px;
  z-index: 100;
  position: relative;
  @media (max-width: 768px) {
    padding-top: 24px;
  }
`;

export const HeaderContents = styled.div<{ open: boolean }>`
  background-color: white;
  z-index: 100;
  border-radius: 12px;
  height: 80px;
  padding: 0 24px;
  box-shadow: 0 4px 23px 0 rgba(0,0,0,.3);
  @media (max-width: 768px) {
    margin: 0px 12px;
  }
  @media (min-width:768px) and (max-width: 1200px) {
    margin: 0px 12px;
  }
`

export const HeaderColLeft = styled(Col)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const HeaderColCenter = styled(HeaderColLeft)`
  justify-content: center;
  @media (max-width: 768px) {
    display: none !important;
  }
`

export const HeaderColRight = styled(HeaderColLeft)`
  justify-content: flex-end;
`

export const StyledLink = styled(Link)`
	color: #333E63;
	font-size: 18px;
	font-weight: 600;
`

export const StyledLinkMobileCol = styled(Col)`
  margin-bottom: 24px;
`

export const StyledLinkMobile = styled(Link)`
	color: #333E63;
	font-size: 24px;
	font-weight: 600;
  width: 100%;
  display:inline-block;
`

export const LinksRow = styled.nav`
  min-width: 300px;
  max-width: 450px;
  width: 100%;
  margin-top: 4px;
`

export const ButtonWrapper = styled.div`
  @media (max-width: 768px) {
    display: none !important;
  }
`

export const MenuWrapper = styled.div`
  @media (min-width: 768px) {
    display: none !important;
  }
`

export const HeaderMenu = styled.div<{ open: boolean }>`
  height: ${props => (props.open ? "400px" : "0px")};
  pointer-events: ${props => (props.open ? "inherit" : "none")};
  background-color: white;
  z-index: -1;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  transition: height 0.3s;
  margin: -16px 12px 0;
  & div {
    display: inherit;
  }
`

export const MenuContents = styled.div<{ open: boolean }>`
  padding: 48px 24px 24px;
  transition: all 0.3s;
  transition-delay: ${props => (props.open ? "0.3s" : "0s")};
  height: ${props => (props.open ? "400px" : "0px")};
  opacity: ${props => (props.open ? 1 : 0)};
  overflow: hidden;
`;